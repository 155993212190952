@import 'src/assets/styles/custom/variables';

.checkbox {
  &__label {
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    user-select: none;
  }

  &__text {
    a {
      font-weight: 600;
      color: $primary;
      text-decoration: none;
    }
  }

  &__mark {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border: 1px solid $greyDark;
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      background: url('../../../../assets/images/svg/check.svg') 50% 50%
        no-repeat;
      background-size: 100% 100%;
      opacity: 0;
    }
  }

  &__radio {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border: 1px solid $greyDark;
    border-radius: 12px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      transform: translate(-50%, -50%);
      background-color: $purple;
      background-size: 100% 100%;
      opacity: 0;
    }
  }

  &--minified {
    .checkbox {
      &__mark {
        width: 12px;
        height: 12px;
        margin-right: 8px;

        &::before {
          width: 8px;
          height: 8px;
        }
      }
      &__radio {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 8px;

        &::before {
          width: 8px;
          height: 8px;
        }
      }

      &__text {
        font-size: 12px;
        color: $greyDark;
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }

  input {
    display: none;

    &:checked {
      & + .checkbox__mark {
        background-color: $purple;
        border-color: $purple;

        &::before {
          opacity: 1;
        }
      }
      & + .checkbox__radio {
        border-color: $purple;
        border-width: 2px;

        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__group {
    padding: 10px 0px;

    p {
      font-family: $mainFont;
      font-weight: 600;
      font-size: 14px;
    }

    .checkbox {
      padding: 8px 0px;
    }

    &--error {
      p {
        color: red;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .checkbox {
    &__mark {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      &::before {
        width: 10px;
        height: 10px;
      }
    }
    &__radio {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      margin-right: 8px;

      &::before {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
    }
  }
}
