@import 'src/assets/styles/custom/variables';

.pp-info {
  &__image-wrap {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: default;

    img {
      width: 136px;
      height: 136px;
      object-fit: cover;
      cursor: pointer;
    }

    &:hover {
      .pp-info__zoom-ic {
        opacity: 1;
      }
    }
  }
  &__zoom-ic {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.2s;
    opacity: 0;
    cursor: pointer;
    color: $white;
  }
}

.pp-info-full {
  img {
    height: 400px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
