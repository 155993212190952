@import "src/assets/styles/custom/variables";

.form-group {
  margin: 0 -8px 16px;

  &__title {
    font-family: $altFont;
    font-size: 12px;
    font-weight: 600;
    color: $greyDark2;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input, .datepicker, .select-menu, .autocomplete {
      width: calc(50% - 8px);
      margin-bottom: 16px;
    }
  }

  &--full {
    margin: 0 0 16px;

    .form-group {
      &__fields {
        .input, .datepicker, .select-menu, .autocomplete {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .form-group {
    margin: 0 0 16px;

    .input, .datepicker, .select-menu {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
