@import 'src/assets/styles/custom/variables';

.notification-settings {
  display: flex;
  min-width: 600px;
  padding-top: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;
  flex-direction: column;

  .Toastify__close-button > svg {
    height: 16px !important;
    width: 14px !important;
  }

  &__title {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: bold;
    }
  }

  &__save {
    margin-top: 20px;
    align-self: flex-end;
    button {
      width: 150px;
    }
  }

  @media (max-width: $tabBreakpoint) {
    &__save {
      align-self: center;
      button {
        width: 30vw;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 12px 0px;
    border: 1px solid #e5e5ea;
    border-radius: 8px;

    .column-header {
      margin: 0px 12px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px dashed #cdcdd4;

      font-style: normal;
      font-weight: 600;
      color: #7f7f8c;
    }

    .singular-col {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      [class*='-column'] {
        margin-left: 12px;
        min-width: 300px;
        width: 33%;
      }

      .main-column {
        border-right: 1px solid #e5e5ea;
      }

      .item {
        margin: 6px 12px;

        span {
          color: #7f7f8c;
        }
      }

      .disabled {
        opacity: 50%;
      }
    }
    @media (max-width: $tabBreakpoint) {
      .singular-col {
        justify-content: center;

        .sub-column {
          margin-left: 70px;
        }
      }
    }
  }
}
