@import 'src/assets/styles/custom/variables';

.header {
  &__content {
    display: flex;
    height: 72px;
    padding: 0 24px;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex: 1;

    &-icon {
      width: 48px;
      height: 48px;
      margin-right: 12px;
      font-family: $altFont;
      font-size: 14px;
      line-height: 48px;
      font-weight: 600;
      text-align: center;
      border-radius: 16px;
      overflow: hidden;
      color: $white;
      background-color: $mainDark;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-family: $altFont;
      font-weight: 600;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: $mobileBreakpoint) {
  .header {
    height: auto;

    &__logo {
      width: 128px;
      height: 40px;
    }
  }
}
