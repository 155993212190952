.entry-criteria {
  min-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: bold;
    }

    .edit-button {
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 0px;
    border: 1px solid #e5e5ea;
    border-radius: 8px;

    .section-column {
      flex: 1;
      min-width: 200px;
    }

    .divider {
      border-right: 1px solid #e5e5ea;
    }

    .column-header {
      margin: 0px 12px;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cdcdd4;

      font-style: normal;
      font-weight: 600;
      color: #7f7f8c;
    }

    .result-item {
      margin: 16px 12px;

      span {
        color: #7f7f8c;
      }
    }
  }
}
