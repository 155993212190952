$mainFont: 'Inter', sans-serif;
$altFont: 'Epilogue', sans-serif;

$white: #ffffff;
$black: #000000;
$primary: #57cd66;
$primaryLight: rgba(87, 205, 102, 0.1);
$mainDark: #121219;
$grey: #d4d4d5;
$greyLight: #dadadc;
$greyLight2: #b4b4bc;
$greyLight3: #7f7f8c;
$greyDark: #a4a4a7;
$greyDark2: #858589;
$greyDark3: #6a6a77;
$bg: #f4f4f4;
$bg2: #f6f5fa;
$border2: #a5adad;
$border: #c2c2c4;
$orange: #f26f46;
$purple: #463da3;
$primary-50: #f1f1fb;
$primary-100: #a0a0ec;
$primary-500: #463da3;
$neutralGray-900: #121219;
$error-500: #d80936;
$warning-500: #ea8915;

$mobileBreakpoint: 676px;
$tabBreakpoint: 1080px;

:root {
  --toastify-toast-width: 400px;
  --toastify-color-light: #463da3;
  --toastify-text-color-light: #fff;
}
