@import 'src/assets/styles/custom/variables';

.ReactModal {
  &__Content {
     border-radius: 12px !important;
     overflow: hidden;
  }
}

.criteria-form {
  width: 60vw;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px 10px 20px;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      margin-left: 5px
    }
  }

  &__content {
    background-color: rgba(240, 240, 244, 0.5);
    max-height: 70vh;
    overflow-y: scroll;

    .form-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 15px;

      .button {
        margin-right: 15px;
        font-family: $mainFont;
      }
    }
  }

  .react-tabs {
    &__tab-list {
      margin-bottom: 0px;
      background-color: $white;
      position: fixed;
      z-index: 1000;
      background-color: $white;
      width: 60vw;
    }

    &__tab {
      color: $greyDark3;
      font-weight: 700;
    }

    &__tab--selected {
      color: $purple;
      border-bottom-color: $purple;
    }
  }

  .form-tab {
    padding: 60px 30px;
  }
  
}