@import "src/assets/styles/custom/variables";

.t-user {
  padding: 20px;

  &__head {
    margin-bottom: 30px;
  }

  &__common {
    display: flex;

    .user-info-item {
      margin-right: 40px;
    }
  }

  &__status {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    background-color: $primaryLight;
  }
}
