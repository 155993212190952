.user-info-item {
  display: flex;
  align-items: center;

  .button--empty {
    text-decoration: underline;
  }

  &__title {
    width: 200px;
    font-weight: 600;
  }
}
