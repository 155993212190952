@import 'src/assets/styles/custom/variables';

.notifications {
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  &-button {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: $greyLight;
    }
  }

  &-badge {
    position: absolute;
    top: -2px;
    right: -5px;
    pointer-events: none;

    &-content {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: #d80936;

      justify-content: center;
      align-items: center;
      text-align: center;
      &-counter {
        font-family: $altFont;
        font-size: 12px;
        font-weight: 600;
        color: white;
      }
    }

    .extended-1 {
      width: 25px;
    }
    .extended-2 {
      width: 33px;
    }
  }
  .extended-1 {
    right: -10px;
  }
  .extended-2 {
    right: -18px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .notifications {
    .counter {
      .circle {
        .number {
        }
      }
    }
  }
}
