@import "src/assets/styles/custom/variables";

.file-picker {
  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__btn {
    height: 48px;
    min-width: 105px;
    padding: 0 20px;
    font-family: $altFont;
    font-size: 16px;
    font-weight: 600;
    line-height: 46px;
    border-radius: 12px;
    color: $primary;
    background-color: $white;
    border: 1px solid $primary;
    cursor: pointer;
    text-align: center;
  }

  &__name {
    margin-left: 20px;
  }

  input {
    display: none;
  }
}
