@import "src/assets/styles/custom/variables";

.mfp-user {
  padding-top: 30px;

  &__title {
    margin-bottom: 30px;
    font-family: $altFont;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
  }
}