@import "src/assets/styles/custom/variables";

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange;

  &__text {
    margin-right: 5px;
  }

  &__value {
    text-align: left;
    width: 33px;
  }
}
