.rd-details {
  display: flex;
  padding-top: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;

  &__title {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  &__content {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    width: 20%;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    width: 60%;
    .button {
      min-width: 150px;
      width: 300px;
      margin-top: 10px;
    }
  }
}

.duplicates-list {
  width: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__item-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 0.5px solid #c0c0c0;
  }

  &__item-container-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid gray;
    width: 100%;
  }

  &__item {
    display: flex;
  }

  &__header-item {
    font-weight: bold;
  }
}
