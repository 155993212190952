@import "src/assets/styles/custom/variables";

.host-select-menu {
  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__menu {
    z-index: 1000;
  }

  &__menu-list {
    height: 200px;
  }
  &__control {
    height: 48px;
    border-radius: 12px !important;
    background: $bg !important;
    border: 1px solid $bg !important;
    box-shadow: none !important;
  }

  &__value-container {
    padding: 0 16px;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    width: 36px !important;
    height: 36px !important;
  }

  &__error {
    p {
      font-size: 12px;
      color: red !important;
    }
  }

  &__wrap {
    position: relative;
  }

  &__clear-btn {
    position: absolute;
    right: 2rem;
    top: 50%;
    background-color: transparent;
    border: none;
    transform: translateY(-50%);
  }
}

@media (max-width: $mobileBreakpoint) {
  .select-menu {
    &__control {
      height: 40px;
    }
  }
}
