@import "src/assets/styles/custom/variables";

.user {
  padding: 20px;

  &__head {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button {
      background-color: $white;
      border: 1px solid $purple;

      p {
        color: $purple;
      }
    }
  }

  &__common {
    display: flex;
    flex-wrap: wrap;

    .user-info-item {
      width: 50%;
      margin-bottom: 15px;
    }
  }

  &__items {
    //display: flex;
    padding-top: 30px;

    &-section {
      margin-bottom: 50px;
      //&:first-child {
      //  width: 70%;
      //  padding-right: 10px;
      //}
      //
      //&:last-child {
      //  width: 30%;
      //  padding-left: 10px;
      //}
    }

    &-title {
      margin-bottom: 25px;
      font-family: $altFont;
      font-size: 20px;
      font-weight: 600;
    }
  }
}