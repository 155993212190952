@import "src/assets/styles/custom/variables";
.timer-counter {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
        margin-right: 13.67px;
    }
}

.done > * {
    color: $error-500;
    fill: $error-500;
}
.pending > * {
    color: $warning-500;
    fill: $warning-500;
}

