@import "src/assets/styles/custom/variables";

.items-counter {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 20px;
  }

  &__value {
    padding: 0 15px;
    height: 50px;
    line-height: 48px;
    color: $primary;
    border: 1px solid $border;
  }
}
