@import "src/assets/styles/custom/variables";

.autocomplete {
  position: relative;

  input {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border-radius: 12px;
    background: $bg;
    border: 1px solid $bg;
    font-size: 16px;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  .react-autosuggest {
    &__suggestions-list {
      position: absolute;
      z-index: 100;
      left: 0;
      top: 100%;
      width: 100%;
      padding: 15px 0;
      max-height: 200px;
      overflow-y: auto;
      list-style: none;
      background-color: $white;
      border: 1px solid $border;
    }

    &__suggestion {
      padding: 0 10px;
      cursor: pointer;
    }
  }
}
