@import "src/assets/styles/custom/variables";

.password-validator {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 8px;

  &__text {
    margin-right: 18px;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .checkbox {
    margin-right: 12px;
    margin-bottom: 8px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .password-validator {
    .checkbox {
      width: 100%;
    }
  }
}
