.audit-header {
  &__actions {
    display: flex;
    align-items: flex-start;
  }

  &__filter {
    display: flex;
    align-items: flex-end;

    .datepicker {
      margin-left: 20px;
    }
  }

  &__export {
    margin-left: 30px;

    &-title {
      margin-bottom: 10px;
    }
  }
}