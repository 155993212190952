.ReactModal {
  &__Overlay {
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgba(18, 18, 25, 0.8) !important;
  }

  &__Content {
    position: static !important;
    border: none !important;
    border-radius: 32px !important;
  }
}
