@import "src/assets/styles/custom/variables";

.collapse-pane {
  &__head {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    transition: 0.4s;
  }

  .Collapsible {
    &__trigger {
      display: block;
      padding: 15px 0;
      text-align: center;
      font-weight: bold;
      background-color: $primaryLight;
      cursor: pointer;
      user-select: none;

      &.is-open {
        .collapse-pane__icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}
