@import 'src/assets/styles/custom/variables';

.host {
  &__main {
    display: flex;
    justify-content: center;

    .host-create-form {
      margin: 0 30px 0 0;
    }
  }

  &__content {
    padding: 30px 24px;
  }

  &__forms {
    padding-top: 50px;
  }

  &__entrycriteria {
    margin-top: 50px;
  }

  &__items {
    display: flex;
    padding-top: 30px;

    &-section {
      &:first-child {
        width: 70%;
        padding-right: 10px;
      }

      &:last-child {
        width: 30%;
        padding-left: 10px;
      }
    }

    &-title {
      margin-bottom: 25px;
      font-family: $altFont;
      font-size: 20px;
      font-weight: 600;
    }
  }

  &__invoices {
    td,
    th {
      padding: 0 10px;
    }
  }

  &__download-check {
    text-align: center;
  }
}
