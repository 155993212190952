@import "../../../../assets/styles/custom/variables";

.input {
  position: relative;

  input {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border-radius: 12px;
    background: $bg;
    border: 1px solid $bg;
    font-size: 16px;

    &[type="password"] {
      color: $greyDark;
    }

    &::placeholder {
      color: $greyDark;
    }
  }

  &--reset {
    input {
      padding-right: 40px;
    }
  }

  &--completed {
    input {
      background-color: rgba(20, 76, 217, 0.05);
    }
  }

  &--error {
    p {
      font-size: 12px;
      color: red !important;
    }
  }

  &--disabled {
    pointer-events: none;

    input {
      background-color: $bg;
      color: hsl(0, 0%, 60%);
    }
  }

  &--end-adornment {
    input {
      border: 0px !important;
    }
  }

  &__with-adornment {
    display: flex;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__reset-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 12px;
    border: none;
    cursor: pointer;
    background: url("../../../../assets/images/svg/remove.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &__password-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 12px;
    border: none;
    cursor: pointer;
    background: url("../../../../assets/images/svg/visibility.svg") 50% 50% no-repeat;
    background-size: 100% 100%;

    &--active {
      background: url("../../../../assets/images/svg/visibility-off.svg") 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }

  &__check {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 12px;
    background: url("../../../../assets/images/svg/check-circle.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &__icon-search {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 14px;
    margin-top: -8px;
  }

  &__end-adornment {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;
  }

  &--search {
    input {
      height: 40px;
      padding-left: 44px;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .input {
    input {
      height: 40px;
      font-size: 14px;
    }

    &__reset-btn {
      bottom: 10px;
    }

    &__check {
      bottom: 10px;
    }
  }
}
