@import 'src/assets/styles/custom/variables';


.Toastify {
  &__close-button > svg {
    height: 16px !important;
    width: 14px !important;
  }
  &__toast-container {
    width: 70% !important;
    max-width: fit-content;
  }
}
// custom toast
.custom-toast {
  display: flex;
  flex-direction: row;
  padding-right: 2rem;

  &__icon {
    margin-right: 1rem;
  }

  &__text {
    font-family: $mainFont;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  &__mfpid {
    text-decoration: underline;
  }
}