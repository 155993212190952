@import "../../../../assets/styles/custom/variables";

.button {
  height: 48px;
  padding: 0 20px;
  font-family: $altFont;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--primary-2 {
    background-color: $primary-500;
    border: 1px solid $primary-500;
  }

  &--secondary {
    background-color: $primaryLight;
    border-color: $primaryLight;
    color: $primary;
  }

  &--empty {
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: $primary;
  }

  &--purple {
    background-color: $purple;
    border-color: $purple;
  }

  &--icon {
    margin-right: 8px;
  }

  &--outline {
    background-color: transparent;
    border-color: $primary-500;
    color: $primary-500;
  }

  &:disabled {
    background-color: $greyDark;
    border-color: $greyDark;
  }

  &--outlined {
    background-color: transparent;

    &__primary {
      color: $primary;
      border: 1px solid $primary;
    }

    &__secondary {
      color: $primary;
      border: 1px solid $primary;
    }

    &__purple {
      color: $purple;
      border: 1px solid $purple
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .button {
    height: 40px;
    font-size: 14px;
    line-height: 38px;
  }
}