.icon {
  display: inline-block;

  &--tag {
    background: url("../../../assets/images/svg/tag.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--search {
    background: url("../../../assets/images/svg/search.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }
};
