.ut-details {
  display: flex;
  padding-top: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;

  &__title {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  &__table {
    width: 100%;
  }
}