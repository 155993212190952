@import 'src/assets/styles/custom/variables';

.notifications-panel {
  position: absolute;
  top: 45px;
  left: -20rem;
  z-index: 5;

  &__arrow {
    position: absolute;
    right: 22%;
    top: -8px;
    width: 18px;
    height: 18px;
    background-color: $purple;
    transform: rotate(45deg);
  }

  &__container {
    min-width: 440px;
    min-height: 300px;
    max-height: 60vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: -2px 5px 15px 7px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -2px 5px 15px 7px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 5px 15px 7px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: stretch;

    .header {
      padding: 10px 16px;
      background-color: $purple;
      display: flex;
      flex-direction: row;
      align-items: center;

      h5 {
        margin-left: 12px;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: white;
      }
    }
    .loading__container {
      width: 100%;
      height: 20vh;
      background-color: #f0f0f4;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body {
      background-color: #f0f0f4;
      overflow:auto;


      .list-section {
        width: 100%;
        background-color: white;

        &-header {
          padding: 8px 18px;
          border-bottom: 1px solid #e5e5ea;

          text-transform: uppercase;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.002em;
        }
      }

      .list-section--divider {
        margin-bottom: 12px;
      }

      .loadmore {
        width: 100%;
        border: none;
        text-align: center;
        padding: 15px 10px;
        background-color: $white;
        border-top: 1px solid #e5e5ea;
        
        font-family: $mainFont;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $primary-500;

        &:hover, &:focus {
          background-color: $primary-50;
        }
      }
    }

    .footer {
      width: 100%;
      padding: 7px 16px;
      text-align: center;
      background-color: $primary-50;
      border: none;

      color: $purple;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .notifications-panel {
  }
}

.notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: white;
  text-decoration: none;
  border: none;

  &:hover {
    background-color: $bg;
    p {
      text-decoration: underline;
    }
  }

  &:active {
    background-color: $primary-50;
  }

  &-content {
    flex: 1;
    margin-left: 18px;
    margin-right: 22px;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      color: $mainDark;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #535361;

      display: flex;
      align-items: center;
      letter-spacing: 0.002em;
    }
  }

  &-time {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #7f7f8c;
  }
}

.notification--divider {
  border-bottom: 1px solid #e5e5ea;
}

.unread {
  background-color: $primary-50;

  &:hover {
    background-color: $primary-50;
  }

  h4 {
    font-weight: bold;
  }
}
