@import "src/assets/styles/custom/variables";

.react-tabs {
  &__tab-list {
    border-bottom-color: $greyLight;
  }

  &__tab {
    border-bottom: 2px solid transparent;

    &--selected {
      font-weight: 600;
      border-color: transparent;
      border-bottom-color: $mainDark;
    }
  }
}
