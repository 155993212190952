@import 'src/assets/styles/custom/variables';

.form {
  padding: 0px;
  max-width: 100%;

  &__title {
    display: none;
  }
}

.user-edit-form {
  width: 80vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  &__head {
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey;

    .form-title {
      font-size: 18px;
      font-weight: bold;
    }

    .close {
      height: 0px;
    }
  }

  &__body {
    background: rgba(240, 240, 244, 0.5);
    overflow-y: scroll;
    max-height: 550px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    .section {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-content: flex-start;
      padding: 0px 15px;

      &__row {
        display: flex;
        flex-direction: row;
      }

      &__title {
        font-size: 16px;
        font-weight: bold;
        color: $greyDark;
        line-height: 24px;
        padding-bottom: 10px;
      }

      .input,
      .datepicker,
      .select-menu {
        width: 100%;
        margin: 10px 20px;
      }

      input,
      select {
        border: 1px solid $greyLight;
        background-color: $white;
      }

      .select-menu {
        &__control {
          background-color: $white !important;
          border: 1px solid $greyLight !important;
        }
      }
    }

    .section-form {
      color: $mainDark;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 18px 12px;

    button {
      margin: 0px 3px;
      font-family: $mainFont;
    }
  }
}
