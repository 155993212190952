.invoice-header {
  &__actions {
    display: flex;
    margin-bottom: 30px;
    align-items: flex-end;
  }

  &__filter {
    display: flex;
    margin: 0 40px;
    align-items: center;

    .datepicker {
      margin: 0 10px;
    }
  }

  &__export {
    &-title {
      margin-bottom: 10px;
    }
  }
}