@import "src/assets/styles/custom/variables";

.two-factor {
    width: 414px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Inter;
    
    &__user {
        width: 342px;
        height: 72px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        background: $primary-50;
        border-radius: 12px;
        margin-bottom: 24px;

        .label {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
        
        .email {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: $neutralGray-900;
        }
    }

    &__title {
        margin: 12px 0px;
    }

    &__subtitle {
        margin-bottom: 24px;
        text-align: center;
    }

    &__qr-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: 'space-between';
        padding: 0px;
        margin-bottom: 24px;

        .qr {
            position: static;
            align-self: center;
            justify-self: center;
            margin-bottom: 18px;
        }

        .button {
            width: 100%;
            margin-bottom: 18px;
        }
        .timer {
            margin-bottom: 24px;
        }
    }

    &__code-actions {
        display: flex;
        flex-direction: column;
        width: 342px;

        .input-label {
            justify-self:  flex-start;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            margin: 8px 0;
        }
        .input {
            width: 100%;
            margin-bottom: 12px;
        }
        .button {
            width: 100%;
            margin-bottom: 18px;
        }
    }
}

.relogin {
    text-align: center;
    font-family: Inter;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 40px 0;
    cursor: pointer;
    color: &primary-500;
}