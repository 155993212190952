@import "src/assets/styles/custom/variables";

.nav-menu-item {
  display: flex;
  padding: 16px 14px;
  align-items: center;
  color: $greyDark;
  text-decoration: none;
  background-color: transparent;
  border: none;

  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  &__text {
    margin-bottom: -2px;
    font-family: $altFont;
    font-size: 14px;
    line-height: 20px;
  }

  &--active {
    color: $mainDark;

    .nav-menu-item__text {
      font-weight: 600;
    }
  }
}
