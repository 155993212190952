@import 'src/assets/styles/custom/variables';

.entry-form-criteria {
  &__disabled {
    opacity: 50%;
  }

  .checkbox {
    padding: 5px 0px;
    input {
      display: none;

      &:checked {
        & + .checkbox__mark {
          background-color: $purple;
          border-color: $purple;
        }
      }
    }
  }

  .divider {
    margin: 20px 0px;
    border: 1px solid $greyLight2;
    border-style: dashed;
  }
  .input {
    padding: 10px 0px;

    input {
      background-color: $white;
      border-radius: 6px;
      border: 1px solid $greyLight2;
    }

    &__with-adornment {
      background-color: $white;
      border-radius: 6px;
      border: 1px solid $greyLight2;
    }

    &__end-adornment {
      p {
        color: $greyLight3;
      }
    }
  }

  .checkbox--options {
    padding: 10px 0px;

    p {
      font-family: $mainFont;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .select-menu {
    padding: 10px 0px;
    &__control {
      background-color: $white !important;
      border-radius: 6px !important;
      border: 1px solid $greyLight2 !important;
    }

    &__option {
      &--is-selected {
        background-color: $white;
        color: $mainDark;
        font-weight: 700;
      }

      &--is-focused {
        background-color: $primary-50;
      }
    }

    &__menu-list {
      height: 150px;
    }
  }
}
