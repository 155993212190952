@import "../../../../assets/styles/custom/variables";

.textarea {
  position: relative;
  width: 100%;

  textarea {
    height: 200px;
    width: 100%;
    padding: 10px 16px;
    border-radius: 12px;
    background: $bg;
    border: 1px solid $bg;
    font-size: 16px;
    line-height: 24px;
    resize: none;

    &[type="password"] {
      color: $greyDark;
    }

    &::placeholder {
      color: $greyDark;
    }
  }

  &--disabled {
    pointer-events: none;

    textarea {
      background-color: $bg;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }
}

@media (max-width: $mobileBreakpoint) {
  .input {
    textarea {
      height: 40px;
      font-size: 14px;
    }
  }
}
