@import "~react-datetime/css/react-datetime.css";
@import "src/assets/styles/custom/variables";

.datepicker {
  position: relative;

  input {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border-radius: 12px;
    background: $bg;
    border: 1px solid $bg;
    font-size: 16px;

    &::placeholder {
      color: $greyDark;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__wrap {
    position: relative;
  }

  //&::before {
  //  content: '';
  //  position: absolute;
  //  z-index: 2;
  //  right: 18px;
  //  bottom: 14px;
  //  width: 20px;
  //  height: 20px;
  //  background: url("../../../../assets/images/svg/calendar.svg") 50% 50% no-repeat;
  //  background-size: 100% 100%;
  //}

  &--disabled {
    pointer-events: none;
  }

  
  &--error {
    p {
      font-size: 12px;
      color: red !important;
    }
  }

  &__clear-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    background-color: transparent;
    border: none;
    transform: translateY(-50%);
  }
}

@media (max-width: $mobileBreakpoint) {
  .datepicker {
    input {
      height: 40px;
      font-size: 14px;
    }

    &::before {
      bottom: 10px;
    }
  }
}
